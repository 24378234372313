<template>
  <div id="chart-wrap"></div>
</template>

<script setup>
import { ref, defineProps, onMounted, watch } from 'vue'
import * as echarts from 'echarts'

const props = defineProps({
  chartData: {
    type: Object,
    default: () => ({
      nodes: [],
      links: [],
      categories: []
    })
  }
})
const chart = ref(null)

onMounted(() => {
  chart.value = echarts.init(document.querySelector('#chart-wrap'))
  setChart()
})

watch(
  () => props.chartData,
  () => {
    setChart()
  },
  { deep: true }
)

// const hexToRgba = (hex, opacity) => {
//   hex = hex.replace('#', '')
//   var r = parseInt(hex.substring(0, 2), 16)
//   var g = parseInt(hex.substring(2, 4), 16)
//   var b = parseInt(hex.substring(4, 6), 16)

//   return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')'
// }

const setChart = () => {
  if (chart.value) {
    const colorMap = new Map([
      ['主题生物标志物', { 0: '#1a85c6', 1: '#ADCE48', 2: '#1a85c6', 3: '#ADCE48' }],
      ['相关疾病', { 0: '#db71a1', 1: '#db71a1', 2: '#c81769', 3: '#db71a1' }],
      ['医学方向', { 0: '#dbb177', 1: '#dbb177', 2: '#c88417', 3: '#dbb177' }],
      ['研究人群', { 0: '#8f6681', 1: '#8f6681', 2: '#4b0331', 3: '#8f6681' }],
      ['相关生物标志物', { 0: '#7da593', 1: '#7da593', 2: '#2b7051', 3: '#7da593' }],
      ['试剂品牌', { 0: '#7e74bd', 1: '#7e74bd', 2: '#372998', 3: '#7e74bd' }],
      ['仪器厂家', { 0: '#87d1a1', 1: '#87d1a1', 2: '#3cb868', 3: '#87d1a1' }]
    ])
    const formatText = (text, maxCharactersPerLine, maxLines) => {
      if (!text || typeof text !== 'string' || maxCharactersPerLine <= 0 || maxLines <= 0) {
        return ''
      }

      const lines = []
      let currentLine = ''
      let lineCount = 0

      for (let i = 0; i < text.length; i++) {
        currentLine += text[i]

        if (currentLine.length === maxCharactersPerLine) {
          lines.push(currentLine)
          currentLine = ''
          lineCount++

          if (lineCount === maxLines) {
            break // Stop processing after reaching maxLines
          }
        } else if (currentLine.length > maxCharactersPerLine) {
          lines.push(currentLine.slice(0, -1) + '...')
          currentLine = ''
          lineCount++

          if (lineCount === maxLines) {
            break // Stop processing after reaching maxLines
          }
        }
      }

      if (currentLine.length > 0) {
        lines.push(currentLine)
      }

      if (lineCount >= maxLines && currentLine.length === 0) {
        // If reached maxLines and the last line is empty, remove the last line and add '...'
        lines.pop()
        lines.push(lines[lines.length - 1].slice(0, -3) + '...')
      }

      return lines.join('\n')
    }

    // 写一个判断中英文的函数
    const isChinese = str => {
      return /[\u4e00-\u9fa5]/.test(str)
    }
    chart.value.setOption({
      tooltip: {},
      series: [
        {
          type: 'graph',
          layout: 'force',
          draggable: true,
          data: props.chartData.nodes.map(item => {
            const level = item.category === '主题生物标志物' ? item.level : item.level + 1
            return {
              ...item,
              itemStyle: {
                color: item.level === 0 ? colorMap.get(item.category)[0] : colorMap.get(item.category)[level]
              }
            }
          }),
          links: props.chartData.links,
          categories: props.chartData.categories,
          roam: true,
          zoom: 1,
          cursor: 'pointer',
          label: {
            show: true,
            position: 'inside',
            color: '#fff',
            rich: {
              a: {
                color: '#fff',
                fontWeight: 800,
                verticalAlign: 'middle'
              },
              b: {
                color: '#fff',
                verticalAlign: 'middle'
              }
            },
            formatter: function (params) {
              const level = params.data.category === '主题生物标志物' ? params.data.level : params.data.level + 1
              const name = params.data.name
              switch (level) {
                case 0:
                  return `{a|${isChinese(name) ? formatText(name, 5, 4) : formatText(name, 10, 2)}}`
                case 1:
                  return `{a|${isChinese(name) ? formatText(name, 4, 3) : formatText(name, 8, 2)}}`
                case 2:
                  return `{b|${isChinese(name) ? formatText(name, 3, 3) : formatText(name, 6, 3)}}`
                case 3:
                  return `{b|${isChinese(name) ? formatText(name, 2, 2) : formatText(name, 4, 2)}}`
              }
            }
          },
          force: {
            repulsion: 200,
            edgeLength: 100,
            friction: 0.1
          }
          // select: {
          //   itemStyle: {
          //     color: '#ccc',
          //     borderColor: '#ccc'
          //   }
          // },
          // selectedMode: 'multiple',
        }
      ]
    })
  }
}
</script>

<style lang="less" scoped>
#chart-wrap {
  width: 100%;
  height: 100%;
}
</style>
