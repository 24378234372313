<template>
  <div class="item-wrap">
    <div
      class="item"
      :class="{ 'active-item': current.indexOf(item.id) !== -1 }"
      v-for="item in list"
      :key="item.id"
      @click="handleClick(item.id)"
    >
      {{ item.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      current: []
    }
  },
  methods: {
    handleClick(id) {
      if (!this.multiple) {
        if (this.current[0] === id) {
          this.current = []
          this.$emit('update:modelValue', this.current)
          this.$emit('change', this.current)
          return
        }
        this.current = [id]
        this.$emit('update:modelValue', this.current)
        this.$emit('change', this.current)
        return
      }
      const index = this.current.indexOf(id)
      if (index === -1) {
        this.current.push(id)
      } else {
        this.current.splice(index, 1)
      }
      this.$emit('update:modelValue', this.current)
      this.$emit('change', this.current)
    }
  },
  watch: {
    modelValue(val) {
      this.current = val
    }
  }
}
</script>

<style lang="less" scoped>
.item-wrap {
  display: flex;
  flex-wrap: wrap;

  .item {
    padding: 7px 16px;
    border: 1px solid #dbe2e6;
    font-size: 14px;
    color: #494e53;
    border-radius: 4px;
    margin-top: 12px;
    margin-right: 12px;
    cursor: pointer;
    user-select: none;
  }

  .active-item {
    border-color: #007fff;
    color: #007fff;
  }
}
</style>
