<template>
  <div class="container">
    <div class="title">画像</div>
    <div class="content">
      <GraphChart :chartData="chartData" />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue'
import { useRoute } from 'vue-router'
import { getChart } from '@/api/zhiku'
import GraphChart from '@/components/charts/echarts/graph/index.vue'

const props = defineProps({
  filterOpt: {
    type: Object,
    default: () => {}
  },
  search: {
    type: String,
    default: ''
  }
})
const route = useRoute()
const chartData = ref({
  nodes: [],
  links: [],
  categories: []
})

watch(
  [() => props.filterOpt, () => props.search],
  () => {
    fetchData()
  },
  {
    deep: true
  }
)

// const unitary = (xmax, xmin, ymax, ymin, num) => {
//   if (xmax === xmin) {
//     return ymax
//   } else {
//     return ymin + ((ymax - ymin) / (xmax - xmin)) * (num - xmin)
//   }
// }
const fetchData = async () => {
  const res = await getChart({
    biomarker__id__in: route.query.biomarkerId,
    search: props.search,
    ...props.filterOpt
  })
  const list = res.data.data.map(item => item.category)
  chartData.value.categories = Array.from(new Set(list)).map(item => ({
    name: item
  }))
  chartData.value.links = res.data.relation
  // const max = Math.max(...res.data.data.map(e => e.count))
  chartData.value.nodes = res.data.data.map(item => ({
    ...item,
    value: item.count,
    symbolSize: item.category === '主题生物标志物' ? 84 : item.level === 0 ? 64 : 36
  }))
}
fetchData()
</script>

<style lang="less" scoped>
.container {
  margin-top: 12px;
  background-color: #fff;
  padding: 16px 0;

  .title {
    padding: 0 16px;
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
  }

  .content {
    width: 100%;
    height: calc(100vh - 118px);
  }
}
</style>
