<template>
  <div class="dashboard-wrap">
    <Card title="地图">
      <div class="map-content-wrap">
        <div style="display: flex; justify-content: center; margin-top: 24px">
          <TabBtn :tabList="mapTabs" :current="mapCurrent" @changeList="handleMapTabClick" />
        </div>
        <div v-if="mapState.loading" class="map-loading-wrap">
          <Loading vertical color="#0C86FE">加载中...</Loading>
        </div>
        <div class="content">
          <EcMap
            v-if="mapCurrent === 'continent'"
            :map="mapCurrent"
            :chartData="mapState.chartData"
            @chartClick="handleMapClick"
          />
          <EcMap
            v-if="mapCurrent === 'country'"
            :map="mapCurrent"
            :chartData="mapState.chartData"
            @chartClick="handleMapClick"
          />
          <EcMap
            v-if="mapCurrent === 'province'"
            :map="mapCurrent"
            :chartData="mapState.chartData"
            @chartClick="handleMapClick"
          />
        </div>
      </div>
    </Card>
    <Card title="发表信息">
      <div style="display: flex; justify-content: center; margin-top: 24px">
        <TabBtn :tabList="publishTabs" :current="publishCurrent" @changeList="handlePubTabClick" />
      </div>
      <div v-if="publishCurrent === '1'">
        <EcHistogram
          echartsId="rank_1"
          :height="230"
          :chartData="publishState.chartData"
          :loading="publishState.loading"
          @on-click="
            data => {
              clickChartHandle(data, '发表趋势')
            }
          "
        />
        <Pagination
          v-if="publishState.totalData.length > 5"
          v-model="publishState.page"
          :total="publishState.totalData.length"
          @change="page => handlePageChage(page, 'publishState')"
        />
      </div>
      <div v-if="publishCurrent === '2'">
        <EcBar
          echartsId="rank_2"
          :height="300"
          :chartData="authorState.chartData"
          :loading="authorState.loading"
          @on-click="
            data => {
              clickChartHandle(data, '通讯作者排名')
            }
          "
        />
        <Pagination
          v-if="authorState.totalData.length > 5"
          v-model="authorState.page"
          :total="authorState.totalData.length"
          :pageSize="5"
          @change="page => handlePageChage(page, 'authorState', 5)"
        />
      </div>
      <div v-if="publishCurrent === '3'">
        <EcBar
          echartsId="rank_3"
          :height="300"
          :chartData="instituteState.chartData"
          :loading="instituteState.loading"
          @on-click="
            data => {
              clickChartHandle(data, '机构排名')
            }
          "
        />
        <Pagination
          v-if="instituteState.totalData.length > 5"
          v-model="instituteState.page"
          :total="instituteState.totalData.length"
          :pageSize="5"
          @change="page => handlePageChage(page, 'instituteState', 5)"
        />
      </div>
    </Card>
    <Card title="生物标记物检测相关">
      <div style="display: flex; justify-content: center; margin-top: 24px">
        <TabBtn :tabList="biomarkerTabs" :current="biomarkerCurrent" @changeList="handleBioTabClick" />
      </div>
      <div v-if="biomarkerCurrent === '1'">
        <EcPie
          echartsId="pie_1"
          :height="260"
          :chartData="biomarkerState.chartData"
          :loading="biomarkerState.loading"
          :legendSplice="5"
          @on-click="
            data => {
              clickChartHandle(data, '生物标记物')
            }
          "
        />
      </div>
      <div v-if="biomarkerCurrent === '2'">
        <EcPie
          echartsId="pie_2"
          :height="260"
          :chartData="instrumentState.chartData"
          :loading="instrumentState.loading"
          :legendSplice="5"
          @on-click="
            data => {
              clickChartHandle(data, '仪器厂家')
            }
          "
        />
      </div>
      <div v-if="biomarkerCurrent === '3'">
        <EcPie
          echartsId="pie_3"
          :height="260"
          :chartData="reagentState.chartData"
          :loading="reagentState.loading"
          :legendSplice="5"
          @on-click="
            data => {
              clickChartHandle(data, '试剂厂家')
            }
          "
        />
      </div>
    </Card>
    <Card title="研究信息统计">
      <div style="display: flex; justify-content: center; margin-top: 24px">
        <TabBtn :tabList="populationTabs" :current="populationCurrent" @changeList="handlePopulationTabClick" />
      </div>
      <div v-if="populationCurrent === '1'">
        <EcPie
          echartsId="pie_4"
          :height="300"
          :center="['50%', '35%']"
          :chartData="drugState.chartData"
          :loading="drugState.loading"
          :legendSplice="5"
          @on-click="
            data => {
              clickChartHandle(data, '相关药物')
            }
          "
        />
      </div>
      <div v-if="populationCurrent === '2'">
        <EcPie
          echartsId="pie_5"
          :height="300"
          :chartData="diseaseState.chartData"
          :loading="diseaseState.loading"
          :legendSplice="5"
          @on-click="
            data => {
              clickChartHandle(data, '相关疾病')
            }
          "
        />
      </div>
      <div v-if="populationCurrent === '3'">
        <EcPie
          echartsId="pie_6"
          :height="300"
          :chartData="medicalState.chartData"
          :loading="medicalState.loading"
          :legendSplice="5"
          @on-click="
            data => {
              clickChartHandle(data, '医学方向')
            }
          "
        />
      </div>
      <div v-if="populationCurrent === '4'">
        <EcPie
          echartsId="pie_7"
          :height="300"
          :chartData="populationState.chartData"
          :loading="populationState.loading"
          :legendSplice="5"
          @on-click="
            data => {
              clickChartHandle(data, '研究人群')
            }
          "
        />
      </div>
    </Card>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Loading } from 'vant'
import Card from '@/components/card/'
import TabBtn from '@/views/dashboard/components/TabBtn'
import { EcHistogram, EcPie, EcMap, EcBar } from '@/components/charts/'
import Pagination from '@/components/pagination/'
import {
  getYear,
  getZhiKuDashboardAuthor,
  getZhiKuDashboardBiomarker,
  getZhiKuDashboardContinent,
  getZhiKuDashboardCountry,
  getZhiKuDashboardDiseases,
  getZhiKuDashboardInstitution,
  getZhiKuDashboardInstrument,
  getZhiKuDashboardMedicals,
  getZhiKuDashboardPopulations,
  getZhiKuDashboardProvince,
  getZhiKuDashboardReagent,
  getZhiKuDashboardTreatments
} from '@/api/zhiku'
import ZhCountry from '@/views/constant/zh_country.json'

const mapTabs = [
  { name: '按大洲', key: 'continent' },
  { name: '按国家', key: 'country' },
  { name: '按省份', key: 'province' }
]
const publishTabs = [
  { name: '发表趋势', key: '1' },
  { name: '通讯作者排名', key: '2' },
  { name: '机构排名', key: '3' }
]
const biomarkerTabs = [
  { name: '生物标记物', key: '1' },
  { name: '仪器厂家', key: '2' },
  { name: '试剂厂家', key: '3' }
]
const populationTabs = [
  { name: '相关药物', key: '1' },
  { name: '相关疾病', key: '2' },
  { name: '医学方向', key: '3' },
  { name: '研究人群', key: '4' }
]

const route = useRoute()
const router = useRouter()
const mapCurrent = ref('country')
const publishCurrent = ref('1')
const biomarkerCurrent = ref('1')
const populationCurrent = ref('1')
const mapState = reactive({
  type: 'continent',
  chartData: [],
  loading: true
})
const publishState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const authorState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const instituteState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const biomarkerState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const instrumentState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const reagentState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const drugState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const diseaseState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const medicalState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const populationState = reactive({
  chartData: [],
  loading: true,
  page: 1,
  totalData: []
})
const state = {
  mapState,
  publishState,
  authorState,
  instituteState,
  biomarkerState,
  instrumentState,
  reagentState,
  drugState,
  diseaseState,
  medicalState,
  populationState
}

const pageDataSlice = (arr, page, pageSize = 5) => {
  const copyArr = [...arr]
  const len = arr.length
  if (len <= pageSize) {
    return arr
  }
  return copyArr.slice((page - 1) * pageSize, page * pageSize)
}

const handlePageChage = (page, key, pageSize = 5) => {
  console.log(state[key])
  if (state[key]) {
    state[key].chartData = pageDataSlice(state[key].totalData, page, pageSize)
  }
}

const fetchMapData = value => {
  if (value === 'continent') {
    mapState.loading = true
    getZhiKuDashboardContinent({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      mapState.chartData = res.data.data
      mapState.loading = false
      console.log(res)
    })
  }
  if (value === 'country') {
    mapState.loading = true
    getZhiKuDashboardCountry({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      mapState.chartData = res.data.data
      mapState.loading = false
      console.log(res)
    })
  }
  if (value === 'province') {
    mapState.loading = true
    getZhiKuDashboardProvince({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      mapState.chartData = res.data.data
      mapState.loading = false
      console.log(res)
    })
  }
}
const fetchPubData = value => {
  if (value === '1') {
    publishState.loading = true
    getYear({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      publishState.totalData = res.data.data.reverse()
      publishState.chartData = pageDataSlice(publishState.totalData, publishState.page)
      publishState.loading = false
    })
  }
  if (value === '2') {
    authorState.loading = true
    getZhiKuDashboardAuthor({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      authorState.totalData = res.data.data
      authorState.chartData = pageDataSlice(authorState.totalData, authorState.page, 5)
      authorState.loading = false
    })
  }
  if (value === '3') {
    instituteState.loading = true
    getZhiKuDashboardInstitution({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      instituteState.totalData = res.data.data
      instituteState.chartData = pageDataSlice(instituteState.totalData, instituteState.page, 5)
      instituteState.loading = false
    })
  }
}
const fetchBioData = value => {
  if (value === '1') {
    biomarkerState.loading = true
    getZhiKuDashboardBiomarker({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      biomarkerState.totalData = res.data.data
      biomarkerState.chartData = res.data.data.map(item => ({
        ...item,
        num: parseInt(res.data.all_count * (item.value / 100))
      }))
      biomarkerState.loading = false
    })
  }
  if (value === '2') {
    instrumentState.loading = true
    getZhiKuDashboardInstrument({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      instrumentState.totalData = res.data.data
      instrumentState.chartData = res.data.data.map(item => ({
        ...item,
        num: parseInt(res.data.all_count * (item.value / 100))
      }))
      instrumentState.loading = false
    })
  }
  if (value === '3') {
    reagentState.loading = true
    getZhiKuDashboardReagent({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      reagentState.totalData = res.data.data
      reagentState.chartData = res.data.data.map(item => ({
        ...item,
        num: parseInt(res.data.all_count * (item.value / 100))
      }))
      reagentState.loading = false
    })
  }
}
const fetchPopulationData = value => {
  if (value === '1') {
    drugState.loading = true
    getZhiKuDashboardTreatments({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      drugState.totalData = res.data.data
      drugState.chartData = res.data.data.map(item => ({
        ...item,
        num: parseInt(res.data.all_count * (item.value / 100))
      }))
      drugState.loading = false
    })
  }
  if (value === '2') {
    diseaseState.loading = true
    getZhiKuDashboardDiseases({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      diseaseState.totalData = res.data.data
      diseaseState.chartData = res.data.data.map(item => ({
        ...item,
        num: parseInt(res.data.all_count * (item.value / 100))
      }))
      diseaseState.loading = false
    })
  }
  if (value === '3') {
    medicalState.loading = true
    getZhiKuDashboardMedicals({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      medicalState.totalData = res.data.data
      medicalState.chartData = res.data.data.map(item => ({
        ...item,
        num: parseInt(res.data.all_count * (item.value / 100))
      }))
      medicalState.loading = false
    })
  }
  if (value === '4') {
    populationState.loading = true
    getZhiKuDashboardPopulations({
      biomarker__id__in: route.query.biomarkerId
    }).then(res => {
      populationState.totalData = res.data.data
      populationState.chartData = res.data.data.map(item => ({
        ...item,
        num: parseInt(res.data.all_count * (item.value / 100))
      }))
      populationState.loading = false
    })
  }
}
const fetchData = () => {
  fetchMapData(mapCurrent.value)
  fetchPubData(publishCurrent.value)
  fetchBioData(biomarkerCurrent.value)
  fetchPopulationData(populationCurrent.value)
}
fetchData()

const handleMapTabClick = key => {
  mapCurrent.value = key
  fetchMapData(key)
}
const handlePubTabClick = key => {
  publishCurrent.value = key
  publishState.page = 1
  authorState.page = 1
  instituteState.page = 1
  fetchPubData(key)
}
const handleBioTabClick = key => {
  biomarkerCurrent.value = key
  biomarkerState.page = 1
  instrumentState.page = 1
  reagentState.page = 1
  fetchBioData(key)
}
const handlePopulationTabClick = key => {
  populationCurrent.value = key
  drugState.page = 1
  diseaseState.page = 1
  medicalState.page = 1
  populationState.page = 1
  fetchPopulationData(key)
}
const clickChartHandle = (data, title) => {
  router.push(
    `/zhiku/dashboards?biomarkerId=${route.query.biomarkerId}&keyword=${data.key}&value=${data.name}&title=${title}`
  )
}

const handleMapClick = data => {
  const titleMap = {
    continent: '大洲',
    country: '国家',
    province: '省份'
  }
  if (data) {
    router.push(
      `/zhiku/dashboards?biomarkerId=${route.query.biomarkerId}&keyword=${data.key}&value=${
        ZhCountry[data.name] || data.name
      }&title=${titleMap[mapCurrent.value]}`
    )
  }
}
</script>

<style lang="less" scoped>
.dashboard-wrap {
  padding: 0 16px 16px 16px;
}
.map-content-wrap {
  position: relative;
  width: 100%;
  height: 300px;
}
.map-loading-wrap {
  position: absolute;
  top: 27px;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 2;
}
</style>
