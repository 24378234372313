<template>
  <div class="list-wrap">
    <div class="total-wrap">
      <div class="total">共{{ total }}条数据</div>
      <div class="sort">
        <div class="sort-item" @click="handlePublishSort">
          <span>发表时间</span>
          <Sort :type="publishDate" />
        </div>
        <div class="sort-item" @click="handleIfSort">
          <span>IF</span>
          <Sort :type="ifSort" />
        </div>
      </div>
    </div>
    <List ref="myList" v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="item-wrap" v-for="item in listData" :key="item.id" @click="handleDetail(item)">
        <div class="title">{{ item.title }}</div>
        <div class="label-wrap">
          <div class="label">发表年份：</div>
          <div class="value">{{ item.publish_year }}</div>
        </div>
        <div class="label-wrap">
          <div class="label">相关生物标记物：</div>
          <div class="value">{{ item.biomarkers }}</div>
        </div>
        <div class="label-wrap">
          <div class="label">期刊名：</div>
          <div class="value">{{ item.journal }}</div>
        </div>
        <div class="label-wrap">
          <div class="label">概述：</div>
          <div class="value">{{ item.abstract }}</div>
        </div>
      </div>
    </List>
  </div>
</template>

<script setup>
import { ref, defineProps, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { List } from 'vant'
import Sort from '@/components/sort/'
import { getZhikuList } from '@/api/zhiku'

const props = defineProps({
  filterOpt: {
    type: Object,
    default: () => {}
  },
  search: {
    type: String,
    default: ''
  }
})
const route = useRoute()
const router = useRouter()
const page = ref(0)
const total = ref(0)
const myList = ref(null)
const loading = ref(false)
const finished = ref(false)
const publishDate = ref('desc')
const ifSort = ref('desc')
const listData = ref([])

watch(
  [() => props.filterOpt, () => props.search],
  () => {
    console.log(props.filterOpt, 'watchList')
    page.value = 0
    total.value = 0
    listData.value = []
    finished.value = false
    myList.value && myList.value.check()
  },
  {
    deep: true
  }
)

const onLoad = () => {
  page.value++
  getZhikuList({
    page: page.value,
    page_size: 10,
    biomarker__id: route.query.biomarkerId,
    ordering: `${publishDate.value === 'desc' ? '-publish_year' : 'publish_year'},${
      ifSort.value === 'desc' ? '-impact_factor' : 'impact_factor'
    }`,
    search: props.search,
    impact_factor__gte: 0,
    ...props.filterOpt,
    t: new Date().getTime()
  })
    .then(res => {
      listData.value.push(...res.data)
      total.value = res.page.count
      loading.value = false
      if (listData.value.length >= total.value) {
        finished.value = true
      }
    })
    .catch(err => {
      console.log(err)
    })
}
const handleDetail = item => {
  console.log(item)
  if (item.article_data_type === 'guide') {
    router.push(`/zhiku/detail/guide?id=${item.id}`)
  } else {
    router.push(`/zhiku/detail/literature?id=${item.id}`)
  }
}
const handlePublishSort = () => {
  publishDate.value = publishDate.value === 'desc' ? 'asc' : 'desc'
  page.value = 0
  total.value = 0
  listData.value = []
  finished.value = false
  myList.value && myList.value.check()
}
const handleIfSort = () => {
  ifSort.value = ifSort.value === 'desc' ? 'asc' : 'desc'
  page.value = 0
  total.value = 0
  listData.value = []
  finished.value = false
  myList.value && myList.value.check()
}
</script>

<style lang="less" scoped>
.total-wrap {
  height: 40px;
  background: #ffffff;
  border-bottom: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;

  .total {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
  }

  .sort {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 14px;
    display: flex;

    .sort-item {
      display: flex;
      margin-left: 12px;
      & > span {
        margin-right: 4px;
      }
    }
  }
}
.item-wrap {
  background-color: #ffffff;
  padding: 20px 16px;
  border-bottom: 1px solid #e4e4e4;

  .title {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-bottom: 16px;
  }

  .label-wrap {
    display: flex;
    margin-top: 10px;

    .label {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
      white-space: nowrap;
    }
    .value {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 14px;
    }
  }
}
</style>
