<template>
  <div>
    <div class="option-wrap">
      <div class="label-filter-wrap">
        <div class="label">相关疾病</div>
        <div class="value" @click="diseaseSate.show = true">
          <div class="tag-wrap" v-if="diseaseNames.length">
            <div class="tag" v-for="(item, index) in diseaseNames" :key="index">{{ item }}</div>
          </div>
          <div class="text" v-else>点击添加</div>
        </div>
      </div>
      <div class="label-filter-wrap">
        <div class="label">相关生物标志物</div>
        <div class="value" @click="biomarkerSate.show = true">
          <div class="tag-wrap" v-if="biomarkerNames.length">
            <div class="tag" v-for="(item, index) in biomarkerNames" :key="index">{{ item }}</div>
          </div>
          <div class="text" v-else>点击添加</div>
        </div>
      </div>
      <div class="label-filter-wrap">
        <div class="label">治疗</div>
        <div class="value" @click="drugSate.show = true">
          <div class="tag-wrap" v-if="drugNames.length">
            <div class="tag" v-for="(item, index) in drugNames" :key="index">{{ item }}</div>
          </div>
          <div class="text" v-else>点击添加</div>
        </div>
      </div>
      <div class="label-filter-wrap">
        <div class="label">医学方向</div>
        <div class="value" @click="medicalSate.show = true">
          <div class="tag-wrap" v-if="medicalNames.length">
            <div class="tag" v-for="(item, index) in medicalNames" :key="index">{{ item }}</div>
          </div>
          <div class="text" v-else>点击添加</div>
        </div>
      </div>
      <div class="label-filter-wrap">
        <div class="label">研究人群</div>
        <div class="value" @click="researchSate.show = true">
          <div class="tag-wrap" v-if="researchNames.length">
            <div class="tag" v-for="(item, index) in researchNames" :key="index">{{ item }}</div>
          </div>
          <div class="text" v-else>点击添加</div>
        </div>
      </div>
      <div class="label-filter-wrap">
        <div class="label">试剂品牌</div>
        <div class="value" @click="reagentSate.show = true">
          <div class="tag-wrap" v-if="reagentNames.length">
            <div class="tag" v-for="(item, index) in reagentNames" :key="index">{{ item }}</div>
          </div>
          <div class="text" v-else>点击添加</div>
        </div>
      </div>
      <div class="label-filter-wrap">
        <div class="label">仪器-厂家</div>
        <div class="value" @click="instrumentSate.show = true">
          <div class="tag-wrap" v-if="instrumentNames.length">
            <div class="tag" v-for="(item, index) in instrumentNames" :key="index">{{ item }}</div>
          </div>
          <div class="text" v-else>点击添加</div>
        </div>
      </div>
      <div class="label-filter-wrap">
        <div class="label">语言类型</div>
        <FilterItem v-model="languageState.activeId" :multiple="false" :list="languageState.items" />
      </div>
      <div class="label-filter-wrap">
        <div class="label">文献类型</div>
        <FilterItem v-model="literatureTypeState.activeId" :multiple="false" :list="literatureTypeState.items" />
      </div>
      <div class="label-filter-wrap">
        <div class="label">
          <span>发表年份</span>
          <span class="val-wrap"></span>
        </div>
        <div class="my-slide" v-if="publishYearState.min !== ''">
          <div class="number left">{{ publishYearState.activeId[0] }}</div>
          <div style="flex: 1">
            <Slider v-model="publishYearState.activeId" range :min="publishYearState.min" :max="publishYearState.max" />
          </div>
          <div class="number right">{{ publishYearState.activeId[1] }}</div>
        </div>
      </div>
      <div class="label-filter-wrap">
        <div class="label">
          <span>影响因子</span>
          <span class="val-wrap"></span>
        </div>
        <div class="my-slide" v-if="impactFactorState.min !== ''">
          <div class="number left">{{ impactFactorState.activeId[0] }}</div>
          <div style="flex: 1">
            <Slider
              v-model="impactFactorState.activeId"
              range
              :min="impactFactorState.min"
              :max="impactFactorState.max"
            />
          </div>
          <div class="number right">{{ impactFactorState.activeId[1] }}</div>
        </div>
      </div>
    </div>
    <div class="btn-wrap">
      <div class="btn" @click="handleReset">重置</div>
      <div class="btn primary" @click="handlePrimary">确认</div>
    </div>
    <Popup v-model:show="diseaseSate.show" position="bottom">
      <TreeSelect
        v-model:active-id="diseaseSate.activeId"
        v-model:main-active-index="diseaseSate.activeIndex"
        :items="diseaseSate.items"
      />
    </Popup>
    <Popup v-model:show="biomarkerSate.show" position="bottom">
      <TreeSelect
        v-model:active-id="biomarkerSate.activeId"
        v-model:main-active-index="biomarkerSate.activeIndex"
        :items="biomarkerSate.items"
      />
    </Popup>
    <Popup v-model:show="drugSate.show" position="bottom">
      <TreeSelect
        v-model:active-id="drugSate.activeId"
        v-model:main-active-index="drugSate.activeIndex"
        :items="drugSate.items"
      />
    </Popup>
    <Popup v-model:show="medicalSate.show" position="bottom">
      <TreeSelect
        v-model:active-id="medicalSate.activeId"
        v-model:main-active-index="medicalSate.activeIndex"
        :items="medicalSate.items"
      />
    </Popup>
    <Popup v-model:show="researchSate.show" position="bottom">
      <TreeSelect
        v-model:active-id="researchSate.activeId"
        v-model:main-active-index="researchSate.activeIndex"
        :items="researchSate.items"
      />
    </Popup>
    <Popup v-model:show="reagentSate.show" position="bottom">
      <TreeSelect
        v-model:active-id="reagentSate.activeId"
        v-model:main-active-index="reagentSate.activeIndex"
        :items="reagentSate.items"
      />
    </Popup>
    <Popup v-model:show="instrumentSate.show" position="bottom">
      <TreeSelect
        v-model:active-id="instrumentSate.activeId"
        v-model:main-active-index="instrumentSate.activeIndex"
        :items="instrumentSate.items"
      />
    </Popup>
  </div>
</template>

<script setup>
import { reactive, computed, defineEmits, defineProps, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Popup, TreeSelect, Slider } from 'vant'
import FilterItem from '@/components/filter-item'
import {
  getDiseaseList,
  getDrugList,
  getMedicalList,
  getResearchList,
  getBiomarkersList,
  getReagentList,
  getInstrumentList,
  getLiteratureTypeList,
  getPublishYearList,
  getImpactFactorList
} from '@/api/zhiku'
import { filterEmpty } from '@/utils/'

const route = useRoute()
const emit = defineEmits(['reset', 'primary', 'update:modelValue'])
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => null
  },
  show: {
    type: Boolean,
    default: false
  }
})
const diseaseSate = reactive({
  show: false,
  activeId: [],
  activeIndex: 0,
  items: []
})
const biomarkerSate = reactive({
  show: false,
  activeId: [],
  activeIndex: 0,
  items: []
})
const drugSate = reactive({
  show: false,
  activeId: [],
  activeIndex: 0,
  items: []
})
const medicalSate = reactive({
  show: false,
  activeId: [],
  activeIndex: 0,
  items: []
})
const researchSate = reactive({
  show: false,
  activeId: [],
  activeIndex: 0,
  items: []
})
const reagentSate = reactive({
  show: false,
  activeId: [],
  activeIndex: 0,
  items: []
})
const instrumentSate = reactive({
  show: false,
  activeId: [],
  activeIndex: 0,
  items: []
})
const languageState = reactive({
  activeId: [],
  items: [
    { name: '中文', id: '中文' },
    { name: '外文', id: '外文' }
  ]
})
const literatureTypeState = reactive({
  activeId: [],
  items: []
})
const publishYearState = reactive({
  activeId: [],
  min: '',
  max: ''
})
const impactFactorState = reactive({
  activeId: [],
  min: '',
  max: ''
})

const createTreeData = arr => {
  return arr.map(item => {
    return {
      text: item.name,
      id: item.id,
      children: item.children.length
        ? item.children.map(children => {
          return {
            text: children.name,
            id: children.id
          }
        })
        : [{ text: item.name, id: item.id }]
    }
  })
}
// 扁平化树形数据
const flatTreeData = arr => {
  return arr.reduce((prev, cur) => {
    return prev.concat(cur.children)
  }, [])
}

getDiseaseList({
  biomarker__id__in: route.query.biomarkerId
}).then(res => {
  if (res.code === 200) {
    diseaseSate.items = createTreeData(res.data)
  }
})
getBiomarkersList({
  biomarker__id__in: route.query.biomarkerId
}).then(res => {
  if (res.code === 200) {
    biomarkerSate.items = createTreeData(res.data)
  }
})
getDrugList({
  biomarker__id__in: route.query.biomarkerId
}).then(res => {
  if (res.code === 200) {
    drugSate.items = createTreeData(res.data)
  }
})
getMedicalList({
  biomarker__id__in: route.query.biomarkerId
}).then(res => {
  if (res.code === 200) {
    medicalSate.items = createTreeData(res.data)
  }
})
getResearchList({
  biomarker__id__in: route.query.biomarkerId
}).then(res => {
  if (res.code === 200) {
    researchSate.items = createTreeData(res.data)
  }
})
getReagentList({
  biomarker__id__in: route.query.biomarkerId
}).then(res => {
  if (res.code === 200) {
    reagentSate.items = createTreeData(res.data)
  }
})
getInstrumentList({
  biomarker__id__in: route.query.biomarkerId
}).then(res => {
  if (res.code === 200) {
    instrumentSate.items = createTreeData(res.data)
  }
})

getLiteratureTypeList().then(res => {
  const map = {
    指南共识: '1',
    '综述/Meta分析': '2',
    研究: '3',
    其他: '4'
  }
  if (res.code === 200) {
    console.log(res.data, 'res.data')
    literatureTypeState.items = res.data.map(item => {
      return {
        id: map[item],
        name: item
      }
    })
  }
})
getPublishYearList().then(res => {
  if (res.code === 200) {
    publishYearState.min = res.data.min_value
    publishYearState.max = res.data.max_value
  }
})
getImpactFactorList().then(res => {
  if (res.code === 200) {
    impactFactorState.min = res.data.min_value
    impactFactorState.max = res.data.max_value
  }
})

const diseaseNames = computed(() => {
  const flatData = flatTreeData(diseaseSate.items)
  return diseaseSate.activeId.map(item => {
    const foundItem = flatData.find(i => i.id === item)
    return foundItem ? foundItem.text : ''
  })
})
const biomarkerNames = computed(() => {
  const flatData = flatTreeData(biomarkerSate.items)
  return biomarkerSate.activeId.map(item => {
    const foundItem = flatData.find(i => i.id === item)
    return foundItem ? foundItem.text : ''
  })
})
const drugNames = computed(() => {
  const flatData = flatTreeData(drugSate.items)
  return drugSate.activeId.map(item => {
    const foundItem = flatData.find(i => i.id === item)
    return foundItem ? foundItem.text : ''
  })
})
const medicalNames = computed(() => {
  const flatData = flatTreeData(medicalSate.items)
  return medicalSate.activeId.map(item => {
    const foundItem = flatData.find(i => i.id === item)
    return foundItem ? foundItem.text : ''
  })
})
const researchNames = computed(() => {
  const flatData = flatTreeData(researchSate.items)
  return researchSate.activeId.map(item => {
    const foundItem = flatData.find(i => i.id === item)
    return foundItem ? foundItem.text : ''
  })
})
const reagentNames = computed(() => {
  const flatData = flatTreeData(reagentSate.items)
  return reagentSate.activeId.map(item => {
    const foundItem = flatData.find(i => i.id === item)
    return foundItem ? foundItem.text : ''
  })
})
const instrumentNames = computed(() => {
  const flatData = flatTreeData(instrumentSate.items)
  return instrumentSate.activeId.map(item => {
    const foundItem = flatData.find(i => i.id === item)
    return foundItem ? foundItem.text : ''
  })
})

watch(
  () => props.modelValue,
  val => {
    if (val) {
      diseaseSate.activeId = val.filter.disease
      biomarkerSate.activeId = val.filter.biomarkers
      drugSate.activeId = val.filter.drug
      medicalSate.activeId = val.filter.medical
      researchSate.activeId = val.filter.research
      reagentSate.activeId = val.filter.reagent
      instrumentSate.activeId = val.filter.instrument
    }
  },
  {
    immediate: true,
    deep: true
  }
)

watch(
  () => props.show,
  val => {
    if (val) {
      getImpactFactorList({
        ...filterEmpty({
          diseases__id__in: diseaseSate.activeId.join(','),
          biomarker_tags__biomarker__id__in: biomarkerSate.activeId.join(','),
          treatments__id__in: drugSate.activeId.join(','),
          medicals__id__in: medicalSate.activeId.join(','),
          populations__id__in: researchSate.activeId.join(','),
          biomarker_tags__reagent__id__in: reagentSate.activeId.join(','),
          biomarker_tags__instrument__id__in: instrumentSate.activeId.join(','),
          language: languageState.activeId.join(''),
          article_type: literatureTypeState.activeId.join(''),
          publish_year__gte: publishYearState.activeId[0],
          publish_year__lte: publishYearState.activeId[1]
        })
      }).then(res => {
        if (res.code === 200) {
          impactFactorState.min = res.data.min_value
          impactFactorState.max = res.data.max_value
          impactFactorState.activeId = [res.data.min_value, res.data.max_value]
        }
      })
    }
  },
  { immediate: true }
)

const handleReset = () => {
  diseaseSate.activeId = []
  biomarkerSate.activeId = []
  drugSate.activeId = []
  medicalSate.activeId = []
  researchSate.activeId = []
  reagentSate.activeId = []
  instrumentSate.activeId = []
  languageState.activeId = []
  literatureTypeState.activeId = []
  publishYearState.activeId = [publishYearState.min, publishYearState.max]
  impactFactorState.activeId = [impactFactorState.min, impactFactorState.max]
  emit('update:modelValue', null)
  emit('reset')
}
const handlePrimary = () => {
  const params = {
    content: '',
    formulas: {
      disease: diseaseNames.value,
      biomarkers: biomarkerNames.value,
      drug: drugNames.value,
      medical: medicalNames.value,
      research: researchNames.value,
      reagent: reagentNames.value,
      instrument: instrumentNames.value
    },
    filter: {
      id: route.query.biomarkerId,
      name: route.query.biomarkerName,
      disease: diseaseSate.activeId,
      biomarkers: biomarkerSate.activeId,
      drug: drugSate.activeId,
      medical: medicalSate.activeId,
      research: researchSate.activeId,
      reagent: reagentSate.activeId,
      instrument: instrumentSate.activeId
    }
  }
  emit('update:modelValue', params)
  emit('primary', {
    diseases__id__in: diseaseSate.activeId.join(','),
    biomarker_tags__biomarker__id__in: biomarkerSate.activeId.join(','),
    treatments__id__in: drugSate.activeId.join(','),
    medicals__id__in: medicalSate.activeId.join(','),
    populations__id__in: researchSate.activeId.join(','),
    biomarker_tags__reagent__id__in: reagentSate.activeId.join(','),
    biomarker_tags__instrument__id__in: instrumentSate.activeId.join(','),
    language: languageState.activeId.join(''),
    article_type: literatureTypeState.activeId.join(''),
    publish_year__gte: publishYearState.activeId[0],
    publish_year__lte: publishYearState.activeId[1],
    impact_factor__gte: impactFactorState.activeId[0],
    impact_factor__lte: impactFactorState.activeId[1]
  })
}
</script>

<style lang="less" scoped>
.option-wrap {
  background: #fff;
  padding: 16px;
  padding-bottom: 70px;

  .label-filter-wrap {
    margin-bottom: 30px;
    .label {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 8px;
    }

    .value {
      padding: 0 12px;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      font-size: 14px;
      font-weight: 500;
      color: #0c86fe;
      .tag-wrap {
        display: flex;
        flex-wrap: wrap;
        padding: 5px 0;
        .tag {
          margin-right: 12px;
          box-sizing: border-box;
          background: #ebf5ff;
          border-radius: 4px;
          padding: 3px 8px;
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
      .text {
        padding: 8px 0;
      }
    }
  }
}

.btn-wrap {
  display: flex;
  justify-content: space-between;
  padding: 0 16px 30px 16px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .btn {
    width: 48%;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #007aff;
    color: #007aff;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .primary {
    background: #007aff;
    color: #fff;
  }
}

.my-slide {
  margin-top: 20px;
  padding-right: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  &:deep(.van-slider__button) {
    width: 10px;
    height: 10px;
    border: 2px solid #007fff;
  }
  .number {
    min-width: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #627386;
    line-height: 10px;
  }
  .left {
    text-align: right;
    margin-right: 14px;
  }
  .right {
    text-align: left;
    margin-left: 14px;
  }
}
</style>
