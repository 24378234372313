<template>
  <div class="list-page-wrap">
    <ConfigProvider :theme-vars="themeVars">
      <Sticky>
        <div class="search-wrap">
          <div class="search">
            <Field
              v-model="search"
              placeholder="输入搜索关键词，多个词之间逗号分隔"
              :right-icon="rightIcon"
              @click-right-icon="handleClear"
              @keypress="handleSearch"
            />
          </div>
        </div>
        <div style="display: flex; background-color: #fff">
          <div class="tab-wrap">
            <div
              class="tab-item"
              :class="{ 'tab-active': currentTab === item.value }"
              v-for="(item, index) in tabList"
              :key="index"
              @click="currentTab = item.value"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="filter-wrap">
            <span style="margin-right: 4px">筛选</span>
            <Icon
              v-if="isFilter"
              class-prefix="iconfont"
              name="shaixuan"
              size="14"
              color="#0C86FE"
              @click="filterHandle"
            />
            <Icon v-else class-prefix="iconfont" name="a-bianzu71" size="16" @click="filterHandle" />
          </div>
        </div>
      </Sticky>
      <div class="subscribe-wrap" v-if="subscribeNames.length">
        <div class="text">{{ subscribeNames.join('-') }}</div>
        <div class="btn" @click="handleSubscribe">订阅</div>
      </div>
      <div class="content-wrap">
        <List v-if="currentTab === 1" :filterOpt="filterOpt" :search="searchValue" />
        <Portrait v-if="currentTab === 2" :filterOpt="filterOpt" :search="searchValue" />
        <Dashboard v-if="currentTab === 3" />
      </div>
      <Popup v-model:show="filterShow" position="top" :overlay="false" :style="{ height: '100vh' }">
        <FilterComponents v-model="subscribeOpt" :show="filterShow" @reset="handleReset" @primary="handlePrimary" />
      </Popup>
    </ConfigProvider>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import { Field, ConfigProvider, Icon, Sticky, Popup, Toast } from 'vant'
import List from './list.vue'
import Portrait from './portrait.vue'
import Dashboard from './dashboard.vue'
import FilterComponents from './Select.vue'
import { postSubscribe } from '@/api/zhiku'
import { filterEmpty } from '@/utils/'

export default {
  name: 'ZhikuList',
  components: {
    Field,
    ConfigProvider,
    Icon,
    Sticky,
    Popup,
    List,
    Portrait,
    Dashboard,
    FilterComponents
  },
  setup() {
    const themeVars = {
      cellBackgroundColor: 'transparent',
      cellVerticalPadding: '0px',
      cellHorizontalPadding: '0px',
      fieldPlaceholderTextColor: 'rgba(255,255,255,0.6)',
      fieldInputTextColor: '#fff',
      fieldRightIconColor: '#fff',
      treeSelectItemActiveColor: '#0c86fe',
      sidebarSelectedBorderColor: '#0c86fe'
    }
    const tabList = [
      { name: '列表模式', value: 1 },
      { name: '医学画像', value: 2 },
      { name: '数据看板', value: 3 }
    ]
    const store = useStore()
    const route = useRoute()
    const search = ref('')
    const searchValue = ref('')
    const currentTab = ref(1)
    const filterShow = ref(false)
    const filterOpt = ref({})
    const subscribeOpt = ref(null)

    // 二维数组扁平化
    const flatten = arr => {
      return arr.reduce((pre, cur) => {
        return pre.concat(Array.isArray(cur) ? flatten(cur) : cur)
      }, [])
    }

    const rightIcon = computed(() => {
      return search.value !== '' ? 'clear' : 'search'
    })
    const isFilter = computed(() => {
      return Object.keys(filterOpt.value).length > 0
    })
    const subscribeNames = computed(() => {
      return flatten(
        Object.keys(subscribeOpt.value ? subscribeOpt.value.formulas : {}).map(key => subscribeOpt.value.formulas[key])
      )
    })

    if (route.query.filterId) {
      const searchContent = JSON.parse(route.query.filterId)
      const filterData = searchContent.filter
      subscribeOpt.value = searchContent
      filterOpt.value = {
        diseases__id__in: filterData.disease.join(','),
        biomarker_tags__biomarker__id__in: filterData.biomarkers.join(','),
        treatments__id__in: filterData.drug.join(','),
        medicals__id__in: filterData.medical.join(','),
        populations__id__in: filterData.research.join(','),
        biomarker_tags__reagent__id__in: filterData.reagent.join(','),
        biomarker_tags__instrument__id__in: filterData.instrument.join(',')
      }
    }

    const handleSearch = e => {
      if (e.keyCode === 13) {
        searchValue.value = search.value
      }
    }
    const handleClear = () => {
      search.value = ''
      searchValue.value = ''
    }
    const filterHandle = () => {
      filterShow.value = true
    }
    const handleReset = () => {
      filterOpt.value = {}
      filterShow.value = false
    }
    const handlePrimary = opt => {
      if (Object.keys(filterEmpty(opt)).length > 0) {
        filterOpt.value = filterEmpty(opt)
        filterShow.value = false
      } else {
        Toast('请至少选择一个筛选条件')
      }
    }
    const handleSubscribe = () => {
      console.log(subscribeOpt.value)
      if (subscribeOpt.value) {
        postSubscribe(subscribeOpt.value).then(res => {
          if (res.code === 200) {
            Toast('订阅成功')
          }
        })
      }
    }

    onBeforeRouteLeave((to, from, next) => {
      if (to.path.indexOf('/zhiku/detail/') !== -1 || to.path === '/zhiku/dashboards') {
        store.commit('catchComponents/GET_CATCHE_COMPONENTS', ['ZhikuList'])
        next()
      } else {
        store.commit('catchComponents/GET_CATCHE_COMPONENTS', [])
        next()
      }
    })

    return {
      themeVars,
      tabList,
      search,
      searchValue,
      currentTab,
      filterShow,
      filterOpt,
      subscribeOpt,
      rightIcon,
      isFilter,
      subscribeNames,
      handleSearch,
      handleClear,
      filterHandle,
      handleReset,
      handlePrimary,
      handleSubscribe
    }
  }
}
</script>

<style lang="less" scoped>
.list-page-wrap {
  .search-wrap {
    padding: 12px 16px;
    background: #0c86fe;

    .search {
      box-sizing: border-box;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
      padding: 7px 10px;
    }
  }
  .tab-wrap {
    background-color: #fff;
    display: flex;
    flex: 1;

    .tab-item {
      flex: 1;
      padding: 15px 0;
      font-size: 14px;
      font-weight: 400;
      color: rgba(106, 106, 119, 0.7);
      line-height: 14px;
      text-align: center;
    }
    .tab-active {
      color: #0c86fe;
      font-weight: 500;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 50%;
        transform: translateX(-50%);
        width: 24px;
        height: 2px;
        background: #0c86fe;
        border-radius: 2px;
      }
    }
  }
  .filter-wrap {
    display: flex;
    align-items: center;
    padding: 0 16px;
    padding-left: 30px;
    font-size: 14px;
    color: #6a6a77;
    line-height: 14px;
    text-align: center;
  }

  .subscribe-wrap {
    margin-top: 12px;
    background-color: #fff;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .text {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }

    .btn {
      margin-left: 8px;
      padding: 4px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      border-radius: 4px;
      border: 1px solid #0c86fe;
      font-size: 14px;
      color: #0c86fe;
    }
  }

  .content-wrap {
    margin-top: 12px;
  }
}
</style>
